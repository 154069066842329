import React from "react"
import { useLocalStorage } from "react-use"

export const useHistory = (key, id) => {
  const [historyJson, setHistoryJson] = useLocalStorage(key, "[]")
  const history = React.useMemo(() => JSON.parse(historyJson), [historyJson])
  React.useEffect(() => {
    if (id && history && history[0] !== id) {
      setHistoryJson(
        JSON.stringify([id, ...history.filter(historyId => id !== historyId)])
      )
    }
  }, [history])
  return history
}
