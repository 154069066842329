/** @jsx jsx */
import { jsx } from "theme-ui"
import { Spinner } from "@theme-ui/components"
const SpinnerSection = ({ ...restProps }) => {
  return (
    <div
      sx={{
        width: "100%",
        height: "350px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...restProps}
    >
      <Spinner />
    </div>
  )
}
export default SpinnerSection
