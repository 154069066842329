/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Container from "./container"
import calendarImg from "../images/calendar.svg"
import locationPin from "../images/location-pin.svg"
import eventWebsite from "../images/event-website.svg"
import addToCalendar from "../images/add-to-calendar.svg"
import shareEvent from "../images/share-event.svg"
import { formatCardDate, eventToICS } from "../helpers"
import CTA from "./cta"
import Share from "./share"
import Link from "./link"

const IconCTA = ({
  text,
  icon,
  link,
  onClick,
  forceExternal,
  ...restProps
}) => (
  <CTA
    {...(link && { link })}
    {...(onClick && { onClick })}
    forceExternal={forceExternal}
    disabled={!link && !onClick}
    sx={{
      minWidth: "100px",
      width: "100px",
      fontSize: "11px",
      px: 0,
      py: "8px",
      lineHeight: "18px",
      ...(onClick && { cursor: "pointer" }),
      "&:not([disabled]):hover img": {
        filter: "brightness(1000%)",
      },
      "&[disabled] img": {
        filter: "saturate(0%) brightness(150%)",
      },
    }}
    {...restProps}
  >
    <img
      alt=""
      src={icon}
      sx={{
        width: "20px",
        mb: "8px",
      }}
    />
    {text}
  </CTA>
)
const DetailHero = ({ entry, type }) => {
  const [showShare, setShowShare] = React.useState(false)
  const downloadICS = () => {
    const ics = eventToICS({
      startDate: entry.startDate,
      startTime: entry.from,
      endDate: entry.endDate,
      endTime: entry.to,
      title: entry.name,
      description: entry.description,
      location: entry.venue.name || "Various venues",
    })
    const element = document.createElement("a")
    const file = new Blob([ics.value], { type: "text/plain" })
    element.href = URL.createObjectURL(file)
    element.download = "event.ics"
    document.body.appendChild(element)
    element.click()
  }
  const renderVenueDetails = () => (
    <div>
      <Link
        link={"https://www.google.com/maps/place/" + entry.venue.address}
        target="_blank"
        sx={{ fontWeight: "500" }}
      >
        <p sx={{ m: 0, ml: "18px", lineHeight: "1.5", fontSize: "15px" }}>
          {entry.venue.address}
        </p>
        <p sx={{ m: 0, ml: "18px", lineHeight: "1.5", fontSize: "15px" }}>
          {entry.venue.city}, {entry.venue.state} {entry.venue.zip}
        </p>
      </Link>
    </div>
  )
  return (
    <div sx={{ width: "100%" }}>
      <div
        sx={{
          minHeight: ["366px", "366px", "640px"],
          width: "100%",
          backgroundImage: `url(${entry.image}?fm=jpg&fl=progressive)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
      <div
        sx={{
          width: "100%",
          mb: 0,
          backgroundColor: "rgb(247, 246, 242)",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h6
            sx={{
              fontSize: "16px",
              mb: "10px",
              textTransform: "uppercase",
            }}
          >
            /{entry.discipline}
          </h6>
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: ["column", "column", "row"],
              mt: "18px",
            }}
          >
            <div sx={{ width: ["100%", "100%", "55%"] }}>
              <div sx={{ width: ["100%", "100%", "90%", "100%"] }}>
                <h2
                  sx={{
                    fontSize: ["34px", "34px", "62px"],
                    lineHeight: "1,15",
                  }}
                >
                  {entry.name}
                </h2>
              </div>
              <div sx={{ width: ["100%"] }}>
                {type === "venue" && entry.description && (
                  <p sx={{ fontSize: "21px", lineHeight: "30px" }}>
                    {entry.description}
                  </p>
                )}
              </div>
            </div>

            <div
              sx={{
                justifySelf: "flex-end",
                width: ["100%", "100%", "383px"],
              }}
            >
              <div
                sx={{
                  pl: ["5px", "5px", 0],
                  mb: type === "event" ? "38px" : 0,
                }}
              >
                <div
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "flex-start",
                    mb: "20px",
                  }}
                >
                  <img
                    alt=""
                    sx={{ width: "14px", mt: "8px", filter: "invert(100%)" }}
                    src={calendarImg}
                  />
                  <div sx={{ ml: "18px" }}>
                    <p sx={{ m: 0, fontSize: "15px", lineHeight: "21px" }}>
                      {formatCardDate(entry.startDate, entry.endDate)}
                    </p>
                    {entry.from && entry.to && (
                      <p sx={{ m: 0, fontSize: "15px", lineHeight: "21px" }}>
                        {entry.from} - {entry.to}
                      </p>
                    )}
                    {entry.exceptions && (
                      <p sx={{ m: 0, fontSize: "15px", lineHeight: "21px" }}>
                        {entry.exceptions}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <img
                    alt=""
                    sx={{ width: "14px", mt: "5px" }}
                    src={locationPin}
                  />
                  {!!entry.venue ? (
                    entry.venue.website ? (
                      <Link link={entry.venue.website}>
                        {renderVenueDetails()}
                      </Link>
                    ) : (
                      renderVenueDetails()
                    )
                  ) : (
                    entry.venue === false && (
                      <p sx={{ m: 0, ml: "18px", fontSize: "15px" }}>
                        {"Various Venues"}
                      </p>
                    )
                  )}
                </div>
                {entry.venue && (
                  <div>
                    <p sx={{ m: 0, ml: "32px", fontSize: "15px" }}>
                      {entry.venue.phone}
                    </p>
                  </div>
                )}
              </div>
              <div
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {type === "event" && (
                    <CTA
                      forceExternal={true}
                      link={
                        entry.ticketUrl
                          ? entry.ticketUrl?.ticketUrl
                          : entry.venue?.website?.website
                      }
                      disabled={
                        !entry.ticketUrl || !entry.venue?.website?.website
                      }
                      sx={{ py: "24px", mb: "20px" }}
                    >
                      Ticket Information
                    </CTA>
                  )}
                  <div
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mt: type !== "event" ? "50px" : 0,
                    }}
                  >
                    <IconCTA
                      text={"Event Website"}
                      forceExternal={true}
                      icon={eventWebsite}
                      link={entry.eventOrVenueUrl ? entry.eventOrVenueUrl : ""}
                      sx={{ img: { width: "24px", mb: "7px" } }}
                    />
                    <IconCTA
                      text="Add to Calendar"
                      icon={addToCalendar}
                      onClick={() => downloadICS()}
                      sx={{ img: { width: "20px", mb: "7px" } }}
                    />
                    <div>
                      <IconCTA
                        text="Share"
                        icon={shareEvent}
                        sx={{ img: { width: "18px", mb: "5px" } }}
                        onClick={() => setShowShare(true)}
                      />

                      {showShare && (
                        <Share
                          title={entry.name}
                          onClose={() => setShowShare(false)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {type === "event" && (
        <Container sx={{ my: 0, py: 0 }}>
          <div
            sx={{
              width: "50px",
              height: "29px",
              zIndex: "1",
              bottom: "0px",
              clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
              transform: "rotate(180deg)",
              backgroundColor: "rgb(247, 246, 242)",
            }}
          />
        </Container>
      )}
    </div>
  )
}

export default DetailHero
