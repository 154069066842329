import React from "react"
import * as Contentful from "contentful"
import { useStaticQuery, graphql } from "gatsby"

export const useContentfulClient = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contentfulAccessToken
          contentfulSpace
          contentfulIsPreview
        }
      }
    }
  `)

  const getClient = React.useCallback(() => {
    return Contentful.createClient({
      space: data.site.siteMetadata.contentfulSpace,
      accessToken: data.site.siteMetadata.contentfulAccessToken,
      ...(data.site.siteMetadata.contentfulIsPreview && {
        host: `preview.contentful.com`,
      }),
    })
  }, [data])
  return { getClient }
}
