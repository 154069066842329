/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import facebookLogo from "../images/facebook.svg"
import twitterLogo from "../images/twitter-logo.svg"
import emailImage from "../images/mail-icon.svg"
import copyImage from "../images/copy-link.svg"
import { useIsClient } from "../hooks/use-is-client"
import { useCopyToClipboard } from "react-use"
import CTA from "./cta"

const ShareButton = ({ image, text, link, onClick }) => {
  return (
    <CTA
      link={link}
      onClick={onClick}
      sx={{
        border: 0,
        cursor: "pointer",
        color: "initial",
        ":hover": {
          color: "primary",
          backgroundColor: "transparent",
        },
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        px: 0,
        width: "100%",
        alignItems: "center",
        fontSize: "21px",
        py: "12px",
      }}
    >
      <img alt="" src={image} sx={{ width: "25px", mr: "16px" }} />
      {text}
    </CTA>
  )
}
const Share = ({ onClose, title }) => {
  const isClient = useIsClient()
  const url = isClient ? window.location.href : ""
  const [state, copyToClipboard] = useCopyToClipboard()
  return (
    <React.Fragment>
      <div
        onClick={onClose}
        sx={{
          position: "fixed",
          zIndex: 1,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "transparent",
        }}
      ></div>
      <div sx={{ position: "relative", zIndex: 2 }}>
        <div
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "300px",
            background: "white",
            p: "30px",
            mt: "10px",
            boxShadow: "0px 0px 5px rgba(0,0,0,0.4)",
          }}
        >
          <h5
            sx={{
              fontSize: "24px",
              borderBottom: "2px solid #c0c0c0",
              pb: "5px",
              mb: "20px",
            }}
          >
            Share This Page
          </h5>
          <div>
            <ShareButton
              image={twitterLogo}
              link={`https://twitter.com/intent/tweet?text=${title}&url=${encodeURI(
                url || "/"
              )}`}
              text={"Twitter"}
            />
            <ShareButton
              image={facebookLogo}
              onClick={() =>
                isClient &&
                window.open(
                  `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                    url || "/"
                  )}`,
                  "sharer",
                  "top=100,left=100,toolbar=0,status=0,width=600,height=500"
                )
              }
              text={"Facebook"}
            />
            <ShareButton
              image={emailImage}
              link={`mailto:?body=${encodeURIComponent(url)}`}
              text={"Email"}
            />
            <ShareButton
              image={copyImage}
              onClick={() => copyToClipboard(url)}
              text={"Copy Link"}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Share
