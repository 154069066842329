/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Container from "../container"
import twitterLogo from "../../images/twitter-logo.svg"
import instagramLogo from "../../images/instagram-slim.svg"
import facebookLogo from "../../images/facebook.svg"
import youtubeLogo from "../../images/youtube.svg"
import Link from "../link"

const OrganizationInfoSection = ({ organization }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        py: 0,
        mb: "50px",
        p: {
          lineHeight: "16px",
        },
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: organization.description ? "row" : "column",
          mt: ["34px", "34px", "55px"],
        }}
      >
        <div>
          {organization.logo && (
            <img
              src={organization.logo.file.url}
              sx={{ width: "200px", mb: "42px" }}
              alt={organization.name}
            />
          )}
          {!organization.logo && (
            <p sx={{ fontSize: "15px", fontWeight: 500, mb: "10px" }}>
              {organization.name}
            </p>
          )}
          {organization.description && (
            <p
              sx={{
                display: ["none", "none", "block"],
                width: "355px",
                fontSize: "15px",
              }}
            >
              {organization.description.description}
            </p>
          )}
        </div>
        <div
          sx={{
            width: "100%",
            ml: organization.description ? ["28px", "28px", "57px"] : 0,
          }}
        >
          {organization.address && (
            <React.Fragment>
              <p
                sx={{
                  fontSize: "12px",
                  mb: 0,
                  minHeight: "17px",
                }}
              >
                {organization.address}
              </p>
              <p
                sx={{
                  fontSize: "12px",
                  mb: 0,
                  minHeight: "17px",
                }}
              >
                {organization.city}
              </p>
              <p
                sx={{
                  fontSize: "12px",
                  mb: "15px",
                  minHeight: "17px",
                }}
              >
                {organization.state && organization.zip
                  ? `${organization.state}, ${organization.zip}`
                  : organization.state
                  ? organization.state
                  : organization.zip}
              </p>
            </React.Fragment>
          )}
          {organization.email && (
            <Link
              link={`mailto:${organization.email}`}
              target="_blank"
              sx={{ fontSize: "12px", mb: 0, minHeight: "18px" }}
            >
              {organization.email}
            </Link>
          )}
          {organization.website && (
            <Link
              forceExternal={true}
              link={organization.website}
              target="_blank"
            >
              <p sx={{ fontSize: "12px", mb: "15px" }}>
                {organization.website}
              </p>
            </Link>
          )}
          <div sx={{ display: "flex", flexDirection: "row" }}>
            {organization.twitter && (
              <Link
                forceExternal={true}
                link={organization.twitter}
                target="_blank"
              >
                <img alt="" src={twitterLogo} width="24px" sx={{ mr: "9px" }} />
              </Link>
            )}
            {organization.facebook && (
              <Link
                forceExternal={true}
                link={organization.facebook}
                target="_blank"
              >
                <img
                  alt=""
                  src={facebookLogo}
                  width="24px"
                  sx={{ mr: "9px" }}
                />
              </Link>
            )}
            {organization.youtube && (
              <Link
                forceExternal={true}
                link={organization.youtube}
                target="_blank"
              >
                <img alt="" src={youtubeLogo} width="24px" sx={{ mr: "9px" }} />
              </Link>
            )}
            {organization.instagram && (
              <Link
                forceExternal={true}
                link={organization.instagram}
                target="_blank"
              >
                <img alt="" src={instagramLogo} width="24px" />
              </Link>
            )}
          </div>
        </div>
      </div>
      {organization.description && (
        <div sx={{ display: ["block", "block", "none"], mt: "21px" }}>
          <p sx={{ fontSize: "15px", width: "100%" }}>
            {organization.description.description}
          </p>
        </div>
      )}
    </Container>
  )
}

export default OrganizationInfoSection
