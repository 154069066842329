/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Link from "./link"

const CTA = ({ link, classes, forceExternal, ...restProps }) => {
  const style = React.useMemo(() => {
    const styles = {
      default: {
        fontSize: "13px",
        lineHeight: "26px",
        minWidth: "200px",
        minHeight: "46px",
        boxSizing: "border-box",
        p: "8px",
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: theme => `1.49px solid ${theme.colors.primary}`,
        backgroundColor: "white",
        color: "primary",
        transition: "0.1s",
        ":hover": {
          borderColor: "primary",
          backgroundColor: "primary",
          color: "white",
        },
        ":active": {
          borderColor: "secondary",
          backgroundColor: "secondary",
          color: "white",
        },
        ":disabled": {
          borderColor: "muted",
          backgroundColor: "white",
          color: "muted",
        },
      },
      "event-cta-large": {
        minHeight: "75px",
      },
      "navbar-link": {
        display: "inline-block",
        border: 0,
        ":hover": {
          border: 0,
        },
      },
    }
    let finalStyle = { ...styles.default }
    if (classes) {
      classes.forEach(className => {
        finalStyle = { ...finalStyle, ...styles[className] }
      })
    }
    return finalStyle
  }, [classes])
  return link ? (
    <Link forceExternal={forceExternal} link={link} sx={style} {...restProps} />
  ) : (
    <button type="button" sx={style} {...restProps} />
  )
}

export default CTA
