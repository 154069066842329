/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { formatCardDate, toSlug } from "../helpers"
import Link from "./link"

const CollectionCard = ({ collection, hideDetails, ...restProps }) => {
  return (
    <div {...restProps}>
      <Link
        link={`/collection/${toSlug(collection.name)}-${collection.id}`}
        sx={{
          color: "initial",
          "&:hover": { color: ["unset", "unset", "primary"] },
        }}
      >
        <div
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <div
            sx={{
              backgroundImage: `url(${collection.image}?w=512&h=512&fit=fill)`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "100%",
              "&:after": {
                content: "''",
                display: "block",
                pb: "100%",
              },
              position: "relative",
            }}
          >
            <div
              sx={{
                backgroundColor: "primary",
                height: "4px",
                position: "absolute",
                bottom: 0,
                width: "100%",
              }}
            ></div>
          </div>
          <div
            sx={{
              flexGrow: 1,
              width: "100%",
              height: ["290px", "290px", "260px"],
              borderBottom: "4px solid #E0514C",
              lineHeight: "18px",
            }}
          >
            <div
              sx={{
                fontSize: "12px",
                textTransform: "uppercase",
                mt: "12px",
                mb: "12px",
              }}
            >
              /{collection.disciplineName}
            </div>
            <h4
              sx={{
                color: "primary",
                fontSize: "24px",
                mb: "18px",
                lineHeight: "33px",
              }}
            >
              {collection.name.substring(0, 32)}
              {collection.name.length > 32 && "..."}
            </h4>
            {!hideDetails && <React.Fragment></React.Fragment>}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default CollectionCard
